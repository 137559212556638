<template>
  <div>
    <div class="grid-stack">
      <div
        id="ramStatusGrid"
        class="grid-stack-item"
        data-gs-x="0"
        data-gs-y="0"
        data-gs-width="3"
        data-gs-height="7"
      >
        <RAM></RAM>
      </div>
      <div
        id="HDDStatusGrid"
        class="grid-stack-item"
        data-gs-x="0"
        data-gs-y="7"
        data-gs-width="3"
        data-gs-height="7"
      >
        <HDD></HDD>
      </div>
      <div
        id="CPUGrid"
        class="grid-stack-item"
        data-gs-x="3"
        data-gs-y="0"
        data-gs-width="9"
        data-gs-height="14"
      >
        <CPU></CPU>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RAM from "@/components/Admins/Settings/ServerInfo/RAM";
import HDD from "@/components/Admins/Settings/ServerInfo/HDD";
import CPU from "@/components/Admins/Settings/ServerInfo/CPU";

import $ from "jquery";
import { GridStack } from "gridstack";
import "gridstack/dist/gridstack.css";

export default {
  name: "AdminsSettingsServerInfoIndex",
  components: {
    RAM,
    HDD,
    CPU
  },
  beforeRouteLeave(to, from, next) {
    $(".grid-stack").remove();
    next();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.adminSettings"),
        route: ""
      },
      {
        title: this.$t("menu.adminSettingsServer"),
        route: ""
      }
    ]);
    this.loadGridstack();
  },
  methods: {
    loadGridstack() {
      var options = {
        cellHeight: 80,
        margin: 10
      };
      GridStack.init(options);
    }
  }
};
</script>
