<template>
  <!--begin::HDD-->
  <div id="CPUStatus" class="card card-custom grid-stack-item-content">
    <div class="card-header" style="cursor: move">
      <div class="card-title">
        <h3 class="card-label">{{ $t("serverInfo.usageCpu") }}</h3>
      </div>
      <div class="card-toolbar">
        <a
          href="#"
          class="btn btn-icon btn-sm btn-hover-light-primary mr-1"
          data-card-tool="reload"
          data-toggle="tooltip"
          data-placement="top"
          :title="$t('general.reload')"
          @click="reloadCard($event)"
        >
          <i class="fal fa-arrows-rotate"></i>
        </a>
        <a
          href="#"
          class="btn btn-icon btn-sm btn-hover-light-primary"
          data-card-tool="remove"
          data-toggle="tooltip"
          data-placement="top"
          :title="$t('general.remove')"
          @click="closeCard($event)"
        >
          <i class="fal fa-xmark"></i>
        </a>
      </div>
    </div>
    <div class="card-body">
      <apexchart type="bar" :options="chartOptions" :series="series">
      </apexchart>
    </div>
  </div>
  <!--end::HDD-->
</template>

<script>
import { mapGetters } from "vuex";
import { getCPUStatus } from "@/components/Admins/Settings/ServerInfo/serverinfo";
import $ from "jquery";

var cpus = [];

export default {
  name: "AdminsSettingsServerInfoCpu",
  data() {
    return {
      pooling: null,
      chartOptions: {},
      series: [
        {
          name: "Nice",
          data: [0, 0, 0]
        },
        {
          name: "Sys",
          data: [0, 0, 0]
        },
        {
          name: "User",
          data: [0, 0, 0]
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    this.chartOptions = {
      chart: {
        type: "bar",
        height: "auto",
        stacked: true,
        stackType: "100%"
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      yaxis: {
        max: 100,
        labels: {
          show: true,
          minWidth: 0,
          maxWidth: 100
        }
      },
      xaxis: {
        categories: cpus
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40
      },
      colors: ["#feb019", "#ff4560", "#9C27B0", "#eaecf4"],
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val) {
          return val.toFixed(2);
        }
      }
    };
    this.CPU();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  created() {
    this.pollData();
    //this.interval = setInterval(() => this.CPU(), 6000);
  },
  methods: {
    async CPU() {
      var response = await getCPUStatus();

      var niceData = [];
      var sysData = [];
      var userData = [];
      var idleData = [];

      cpus.length = 0;

      $.each(response, function (cpu, data) {
        cpus.push(cpu);
        niceData.push(data.nice);
        sysData.push(data.sys);
        userData.push(data.user);
        idleData.push(data.idle);
      });

      var newSeries = [
        {
          name: this.$t("serverInfo.nice"),
          data: niceData
        },
        {
          name: this.$t("serverInfo.sys"),
          data: sysData
        },
        {
          name: this.$t("serverInfo.user"),
          data: userData
        },
        {
          name: this.$t("serverInfo.idle"),
          data: idleData
        }
      ];
      this.series = newSeries;
      return response;
    },
    closeCard() {
      $("#CPUStatus").fadeOut(300, function () {
        $("#CPUStatus").parent().remove();
      });
      this.$destroy();
    },
    reloadCard() {
      this.CPU();
    },
    pollData() {
      this.polling = setInterval(() => {
        this.CPU();
      }, 5000);
    }
  }
};
</script>
