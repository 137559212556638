<template>
  <!--begin::RAM-->
  <div
    v-if="userType === 'admin'"
    id="ramStatus"
    class="card card-custom grid-stack-item-content"
  >
    <div class="card-header" style="cursor: move">
      <div class="card-title">
        <h3 class="card-label">{{ $t("serverInfo.usageRam") }}</h3>
      </div>
      <div class="card-toolbar">
        <a
          href="#"
          class="btn btn-icon btn-sm btn-hover-light-primary mr-1"
          data-card-tool="reload"
          data-toggle="tooltip"
          data-placement="top"
          :title="$t('general.reload')"
          @click="reloadCard($event)"
        >
          <i class="fal fa-arrows-rotate"></i>
        </a>
        <a
          href="#"
          class="btn btn-icon btn-sm btn-hover-light-primary"
          data-card-tool="remove"
          data-toggle="tooltip"
          data-placement="top"
          :title="$t('general.remove')"
          @click="closeCard($event)"
        >
          <i class="fal fa-xmark"></i>
        </a>
      </div>
    </div>
    <div class="card-body">
      <apexchart type="donut" :options="chartOptions" :series="series">
      </apexchart>
    </div>
  </div>
  <!--end::RAM-->
</template>

<script>
import { mapGetters } from "vuex";
import { getRAMStatus } from "@/components/Admins/Settings/ServerInfo/serverinfo";
import $ from "jquery";

export default {
  name: "AdminsSettingsServerInfoRam",
  data() {
    return {
      userType: null,
      polling: null,
      chartOptions: {},
      series: [0, 0, 0]
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    this.userType = this.$store.getters.userType;
    this.chartOptions = {
      chart: {
        height: "auto",
        type: "donut"
      },
      labels: [
        this.$t("serverInfo.free"),
        this.$t("serverInfo.used"),
        this.$t("serverInfo.buffered")
      ],
      colors: ["#00e396", "#ff4560", "#feb019"],
      tooltip: {
        y: {
          formatter: function (value) {
            return value / 1000 + " GB";
          }
        }
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetX: -15
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: "22px"
              },
              value: {
                fontSize: "16px"
              },
              total: {
                show: true,
                showAlways: true,
                label: this.$t("serverInfo.total"),
                formatter: function (w) {
                  var totalVal = 0;
                  var series = w.globals.series;
                  $.each(series, function (n, val) {
                    totalVal += val;
                  });
                  return parseInt(totalVal / 1000) + " GB";
                }
              }
            }
          }
        }
      }
    };
    this.RAM();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  created() {
    this.pollData();
    //this.interval = setInterval(() => this.RAM(), 5000);
  },
  methods: {
    async RAM() {
      if (this.userType !== "admin") {
        return false;
      }
      var response = await getRAMStatus("M");
      this.series = [
        parseInt(response.free),
        parseInt(response.used),
        parseInt(response.available - response.free)
      ];
      return response;
    },
    closeCard() {
      $("#ramStatus").fadeOut(300, function () {
        $("#ramStatus").parent().remove();
      });
      this.$destroy();
    },
    reloadCard() {
      this.RAM();
    },
    pollData() {
      this.polling = setInterval(() => {
        this.RAM();
      }, 5000);
    }
  }
};
</script>
