import ApiService from "@/core/services/api.service";

export async function getRAMStatus(unit = "G") {
  var url = process.env.VUE_APP_API_ADMIN;
  ApiService.setHeader();
  return await ApiService.get(url, "server/ram?unit=" + unit)
    .then(response => {
      return response.data.data;
    })
    .catch(error => {
      console.log(error);
      return {
        available: 0,
        free: 0,
        total: 0,
        used: 0
      };
    });
}

export async function getDiskStatus(unit = "G") {
  /*
    var url = process.env.VUE_APP_API_ADMIN;
    ApiService.setHeader();
    return await ApiService.get(url, 'server/disk?unit='+unit)
        .then(response =>{
            return response.data.data;
        })
        .catch(error =>{
            console.log(error);
            return {
                available: 0,
                free: 0,
                total: 0,
                used: 0
            };
        })

   */
  console.log(unit);
  return {
    free: 463,
    used: 49
  };
}

export async function getCPUStatus() {
  var url = process.env.VUE_APP_API_ADMIN;
  ApiService.setHeader();
  return await ApiService.get(url, "server/cpu")
    .then(response => {
      return response.data.data;
    })
    .catch(error => {
      console.log(error);
      return {
        user: 0,
        nice: 0,
        sys: 0,
        idle: 0
      };
    });
}
